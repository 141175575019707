// eslint-disable-next-line import/prefer-default-export
export const sortOrders = {
  asc: {
    name: 'asc',
    fullName: 'ascending',
  },
  desc: {
    name: 'desc',
    fullName: 'descending',
  }
};
